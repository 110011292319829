import React from 'react'
import '../css/amenities.css'

const Amenities = () => {
  return (
    <div>
      <div id='amenities' className='home-amenities reveal'>
        <div className='container'>
          <div className='section-header'>
            <h2>Amenities & Services</h2>
            <p>
              Welcome to ZAYTUN Hotels, where your comfort and satisfaction are
              our top priorities. We take pride in offering an array of
              exceptional amenities and services designed to make your stay
              truly unforgettable. Whether you're here for business or leisure,
              our commitment to excellence shines through in every aspect of
              your experience.
            </p>
          </div>
          <div className='row'>
            <div className='col-md-3 col-sm-6 icons'>
              <i className='icon icon-2'></i>
              <h3>Air Conditioner</h3>
              <p>
                Enjoy personalized climate control in your room with our modern
                air conditioning system, ensuring your comfort throughout your
                stay.
              </p>
            </div>
            <div className='col-md-3 col-sm-6 icons'>
              <i className='icon icon-3'></i>
              <h3>Bathtub</h3>
              <p>
                Indulge in relaxation with a luxurious bathtub, perfect for
                unwinding and soothing your senses after a day of activities..
              </p>
            </div>
            <div className='col-md-3 col-sm-6 icons'>
              <i className='icon icon-4'></i>
              <h3>Shower</h3>
              <p>
                Rejuvenate under the cascading water of our invigorating
                showers, providing a refreshing start or end to your day.
              </p>
            </div>
            <div className='col-md-3 col-sm-6 icons'>
              <i className='icon icon-6'></i>
              <h3>Television</h3>
              <p>
                Stay entertained and informed with a wide range of channels on
                our high-definition televisions, offering a variety of programs
                for your enjoyment.
              </p>
            </div>
            <div className='col-md-3 col-sm-6 icons'>
              <i className='icon icon-7'></i>
              <h3>WiFi</h3>
              <p>
                Stay seamlessly connected with high-speed WiFi, allowing you to
                browse, work, and share your experiences effortlessly.
              </p>
            </div>
            <div className='col-md-3 col-sm-6 icons'>
              <i className='icon icon-8'></i>
              <h3>Telephone</h3>
              <p>
                Our in-room telephone service ensures you're just a call away
                from our attentive staff, ready to assist with any request or
                inquiry.
              </p>
            </div>
            <div className='col-md-3 col-sm-6 icons'>
              <i className='icon icon-9'></i>
              <h3>Mini Bar</h3>
              <p>
                Satisfy your cravings with a well-stocked mini bar, offering a
                selection of beverages and snacks for your convenience.
              </p>
            </div>
            <div className='col-md-3 col-sm-6 icons'>
              <i className='icon icon-10'></i>
              <h3>Kitchen</h3>
              <p>
                For those seeking the comforts of home, our well-equipped
                kitchenettes provide the convenience of preparing meals in the
                privacy of your room.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Amenities
