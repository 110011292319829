import React from 'react'


class VideoPlayer extends React.Component {
  render() {
    return (
      <div className='video-container'>
        <video className='video-player' width='100%' height='auto' controls>
          <source src='/img/zaytun-vid.MP4' type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      </div>
    )
  }
}

export default VideoPlayer
