import React from 'react'
import '../css/footer.css'
import { Link } from 'react-router-dom'
const Footer = () => {
  const now = new Date()
  const year = now.getFullYear()
  return (
    <div>
      <div className='text-center text-lg-start text-white foot reveal'>
        <div className='container p-4 pb-0'>
          <section className=''>
            <div className='row'>
              <div className='col-md-3 col-lg-3 col-xl-3 mx-auto mt-3'>
                <div className='logo-footer'>
                  <img src='/img/logo.png' alt='logo' />
                  <p className='text-white line-height-base mt-3'>
                    Welcome to ZayTun Hotel, your perfect heaven at the heart of
                    the city
                  </p>
                </div>
              </div>

              <hr className='w-100 clearfix d-md-none' />

              <hr className='w-100 clearfix d-md-none' />

              <div className='col-md-3 col-lg-2 col-xl-2 mx-auto mt-3'>
                <h6 className='text-uppercase mb-4 font-weight-bold text-white'>
                  Useful links
                </h6>
                <p className='site'>
                  <Link className='site' to='/'>
                    Home
                  </Link>
                </p>
                <p>
                  <Link className='site' to='/booking'>
                    Booking
                  </Link>
                </p>
                <p>
                  <Link className='site' to='/room'>
                    Our Rooms
                  </Link>
                </p>
                <p>
                  <Link className='site' to='/about'>
                    About
                  </Link>
                </p>
              </div>

              <hr className='w-100 clearfix d-md-none' />

              <div className='col-md-4 col-lg-3 col-xl-3 mx-auto mt-3'>
                <h6 className='text-uppercase mb-4 font-weight-bold text-white'>
                  Contact
                </h6>
                <p className='text-white'>
                  <i className='fas fa-home mr-3'></i> ZayTun Tower Along
                  Zungeru Road, Fagge LGA Kano, Kano State.Nigeria
                </p>
                <p className='text-white'>
                  <i className='fas fa-envelope mr-3'></i>
                  <a href='mailto:info@zaytunhotels.com' className='fw'>
                    info@Zaytunhotels.com
                  </a>
                </p>
                <p className='text-white'>
                  <i className='fas fa-phone mr-3'></i> +2348031531912
                </p>
                <p className='text-white'>
                  <i className='fas fa-phone mr-3'></i> +2349033639385
                </p>
                <p className='text-white'>
                  <i className='fas fa fa-whatsapp mr-3'></i>
                  <a href='https://wa.me/+2348031531912' className='fw'>
                    whatsapp +2348031531912
                  </a>
                </p>
                <p className='text-white'>
                  <i className='fas fa-envelope mr-3'></i>
                  <a href='mailto:zaytunhotel@gmail.com' className='fw'>
                    Zaytunhotel@gmail.com
                  </a>
                </p>
              </div>
            </div>
          </section>

          <hr className='my-3' />

          <section className='p-3 pt-0'>
            <div className='row d-flex align-items-center'>
              <div className='col-md-7 col-lg-8 text-center text-md-start'>
                <div className='p-3'>
                  Copyright © {year}
                  <a className='text-white' href='/'>
                    zaytun Hotels
                  </a>
                  All Rights reserved
                </div>
              </div>

              <div className='col-md-5 col-lg-4 ml-lg-0 text-center text-md-end'>
                <Link
                  className='btn btn-outline-light btn-floating m-1 text-white'
                  role='button'
                >
                  <i className='fab fa-facebook-f'></i>
                </Link>

                <Link
                  className='btn btn-outline-light btn-floating m-1 text-white'
                  role='button'
                >
                  <i className='fab fa-twitter'></i>
                </Link>

                <Link
                  className='btn btn-outline-light btn-floating m-1 text-white'
                  role='button'
                >
                  <i className='fab fa-instagram'></i>
                </Link>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default Footer
