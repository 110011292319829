import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import '../css/search.css'

const Search = () => {
  const [searchDate, setSearchDate] = useState('')
const [searchDate2, setSearchData2] = useState("")
  return (
    <div>
      <div id='search'>
        <div className='container'>
          <div className='form-row'>
            <div className='control-group col-md-3'>
              <label htmlFor='date'>Check-In</label>
              <div className='form-group'>
                <div
                  className='input-group date'
                  id='date'
                  data-target-input='nearest'
                >
                  <DatePicker
                    id='date'
                    selected={searchDate}
                    className='form-control'
                   onChange={(date) =>setSearchDate(date)}
                    placeholderText='MM/DD/YYYY'
                    required
                  />
                  <div
                    className='input-group-append'
                    data-target='#date-3'
                    data-toggle='datetimepicker'
                  >
                    <div className='input-group-text'>
                      <i className='fa fa-calendar'></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='control-group col-md-3'>
              <label htmlFor='date2'>Check-Out</label>
              <div className='form-group'>
                <div
                  className='input-group date'
                  id='date-4'
                  data-target-input='nearest'
                >
                  <DatePicker
                    id='date2'
                    selected={searchDate2}
                    className='form-control'
                    onChange={(date) => setSearchData2(date)}
                    placeholderText='MM/DD/YYYY'
                    required
                  />
                  <div
                    className='input-group-append'
                    data-target='#date-4'
                    data-toggle='datetimepicker'
                  >
                    <div className='input-group-text'>
                      <i className='fa fa-calendar'></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='control-group col-md-3'>
              <div className='form-row'>
                <div className='control-group col-md-6'>
                  <label>Adult</label>
                  <select className='custom-select'>
                    <option defaultValue='selected'>0</option>
                    <option value='1'>1</option>
                    <option value='2'>2</option>
                    <option value='3'>3</option>
                    <option value='4'>4</option>
                  </select>
                </div>
                <div className='control-group col-md-6 kid'>
                  <label>children</label>
                  <select className='custom-select'>
                    <option defaultValue='selected'>0</option>
                    <option value='1'>1</option>
                    <option value='2'>2</option>
                    <option value='3'>3</option>
                    <option value='4'>4</option>
                    <option value='5'>5</option>
                  </select>
                </div>
              </div>
            </div>
            <div className='control-group col-md-3'>
              <Link to='/booking' className='btn btn-block'>
                Search
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Search
