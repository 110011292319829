import React from 'react'
import '../css/call.css'

const Call = () => {
  return (
    <div>
      <div id='call-us' className='reveal'>
        <div className='container'>
          <div className='section-header'>
            <h2>Click Below to Call Us</h2>
            <p>
              For quick booking, Room reservation, Booking confirmations and
              airport pickups call
            </p>
          </div>
          <div className='row'>
            <div className='col-12'>
              <a href='tel:+2348031531912'>+2348031531912</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Call
