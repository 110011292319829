import React from 'react'
import Search from '../components/Search'
import HomeRooms from '../components/HomeRooms'

const Rooms = () => {
  return (
    <div>
      <div className='mt-5'>
        <Search />
      </div>
      <HomeRooms />
    </div>
  )
}

export default Rooms
