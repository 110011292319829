import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './css/style.css'
import './css/scroll.css'
import './css/animate.css'
import './css/hover-style.css'
import './js/animate'

import Header from './components/Header'
import Layouts from './layouts/Layouts'
import About from './Pages/About'
import Contact from './Pages/Contact'
import Rooms from './Pages/Rooms'
import Footer from './components/Footer'
import Amenities from './components/Amenities'
import BookingForm from './components/BookingForm'
import ConfirmationPage from './components/ConfirmationPage'

function App() {
  return (
    <Router>
      <div>
        <Header />

        <Routes>
          <Route path='/' element={<Layouts />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/room' element={<Rooms />} />
          <Route path='/amenities' element={<Amenities />} />
          <Route path='/booking' element={<BookingForm />} />
          <Route path='/confirmation' element={<ConfirmationPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  )
}

export default App
