import React from 'react'
import '../css/welcome.css'
import { Link } from 'react-router-dom'
import VideoPlayer from './VideoPlayer'
const Welcome = () => {
  return (
    <div>
      <div id='welcome' className='reveal'>
        <div className='container'>
          <h3>Welcome to Zaytun Hotel</h3>
          <p> "Your home away from home", designed for ultimate comfort </p>
          <p>Take a virtual tour of our hotel</p>
          <VideoPlayer />
          <Link to='/booking'>Book Now</Link>
        </div>
      </div>
    </div>
  )
}

export default Welcome
