import React from 'react'
import '../css/room.css'
import { Link } from 'react-router-dom'
const HomeRooms = () => {
  return (
    <div>
      <div id='rooms' className='reveal'>
        <div className='container'>
          <div className='section-header'>
            <h2>Our Rooms</h2>
            <p>
              Experience a harmonious blend of comfort and elegance in our
              thoughtfully designed rooms, where every detail is curated to
              enhance your stay.
            </p>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='row'>
                <div className='col-md-3'>
                  <div className='room-img'>
                    <div className='box12'>
                      <img src='/img/single-room.jpg' alt='single-room' />
                      <div className='box-content'>
                        <h3 className='title'>Single Room</h3>
                        <ul className='icon'>
                          <li>
                            <Link
                              to='/booking'
                              data-toggle='modal'
                              data-target='#modal-id1'
                            >
                              <i className='fa fa-link'></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='room-des'>
                    <h3>
                      <button
                        className='link'
                        data-toggle='modal'
                        data-target='#modal-id1'
                      >
                        Single Room
                      </button>
                    </h3>
                    <p>
                      Experience comfort with a Single Queen Bed, a 32-inch
                      smart TV with cable channels, and a cozy reading/laptop
                      table.
                    </p>
                    <ul className='room-icon'>
                      <li className='icon-1'></li>
                      <li className='icon-2'></li>
                      <li className='icon-3'></li>
                      <li className='icon-4'></li>
                      <li className='icon-5'></li>
                      <li className='icon-6'></li>
                      <li className='icon-7'></li>
                      <li className='icon-8'></li>
                      <li className='icon-9'></li>
                      <li className='icon-10'></li>
                    </ul>
                  </div>
                </div>
                <div className='col-md-3 tab'>
                  <div className='room-rate'>
                    <h3>From</h3>
                    <h1 className='reduce'>₦ 28,500 </h1>
                    <Link to='/booking'>Book Now</Link>
                  </div>
                </div>
              </div>
              <hr />
            </div>

            <div className='col-md-12'>
              <div className='row'>
                <div className='col-md-3'>
                  <div className='room-img'>
                    <div className='box12'>
                      <img src='/img/standard-room.jpg' alt='standard-room' />
                      <div className='box-content'>
                        <h3 className='title'>Standard room</h3>
                        <ul className='icon'>
                          <li>
                            <Link
                              to='/booking'
                              data-toggle='modal'
                              data-target='#modal-id2'
                            >
                              <i className='fa fa-link'></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='room-des'>
                    <h3>
                      <button
                        className='link'
                        data-toggle='modal'
                        data-target='#modal-id2'
                      >
                        Standard Room
                      </button>
                    </h3>

                    <p>
                      Embrace spaciousness featuring a Single Queen Bed, a
                      43-inch smart TV with cable channels, and a relaxing
                      single-seat sofa
                    </p>

                    <ul className='room-icon'>
                      <li className='icon-1'></li>
                      <li className='icon-2'></li>
                      <li className='icon-3'></li>
                      <li className='icon-4'></li>
                      <li className='icon-5'></li>
                      <li className='icon-6'></li>
                      <li className='icon-7'></li>
                      <li className='icon-8'></li>
                      <li className='icon-9'></li>
                      <li className='icon-10'></li>
                    </ul>
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='room-rate'>
                    <h3>From</h3>
                    <h1>₦ 33,000</h1>
                    <Link to='/booking'>Book Now</Link>
                  </div>
                </div>
              </div>
              <hr />
            </div>

            <div className='col-md-12'>
              <div className='row'>
                <div className='col-md-3'>
                  <div className='room-img'>
                    <div className='box12'>
                      <img src='/img/delux.jpg' alt='delux' />
                      <div className='box-content'>
                        <h3 className='title'> Standard plus-Delux</h3>
                        <ul className='icon'>
                          <li>
                            <Link
                              to='/booking'
                              data-toggle='modal'
                              data-target='#modal-id5'
                            >
                              <i className='fa fa-link'></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='room-des'>
                    <h3>
                      <button
                        className='link'
                        data-toggle='modal'
                        data-target='#modal-id5'
                      >
                        Standard plus-Delux
                      </button>
                    </h3>
                    <p>
                      Experience comfort with a Very spacious room, Double seat
                      Sofa, Single Queen Bed, a 42 inch smart Tv with Cable
                      channels. Balcony space
                    </p>
                    <ul className='room-icon'>
                      <li className='icon-1'></li>
                      <li className='icon-2'></li>
                      <li className='icon-3'></li>
                      <li className='icon-4'></li>
                      <li className='icon-5'></li>
                      <li className='icon-6'></li>
                      <li className='icon-7'></li>
                      <li className='icon-8'></li>
                      <li className='icon-9'></li>
                      <li className='icon-10'></li>
                    </ul>
                  </div>
                </div>
                <div className='col-md-3 tab'>
                  <div className='room-rate'>
                    <h3>From</h3>
                    <h1>₦ 37,000 </h1>

                    <Link to='/booking'>Book Now</Link>
                  </div>
                </div>
              </div>
              <hr />
            </div>

            <div className='col-md-12'>
              <div className='row'>
                <div className='col-md-3'>
                  <div className='room-img'>
                    <div className='box12'>
                      <img src='/img/executive.jpg' alt='executive-room' />
                      <div className='box-content'>
                        <h3 className='title'>Executive Room</h3>
                        <ul className='icon'>
                          <li>
                            <Link
                              to='/booking'
                              data-toggle='modal'
                              data-target='#modal-id3'
                            >
                              <i className='fa fa-link'></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='room-des'>
                    <h3>
                      <button
                        className='link'
                        data-toggle='modal'
                        data-target='#modal-id3'
                      >
                        Executive Room
                      </button>
                    </h3>
                    <p>
                      Elevate your experience in a super spacious suite with a
                      King size Bed, a 55-inch smart HD TV offering cable
                      channels
                    </p>

                    <ul className='room-icon'>
                      <li className='icon-1'></li>
                      <li className='icon-2'></li>
                      <li className='icon-3'></li>
                      <li className='icon-4'></li>
                      <li className='icon-5'></li>
                      <li className='icon-6'></li>
                      <li className='icon-7'></li>
                      <li className='icon-8'></li>
                      <li className='icon-9'></li>
                      <li className='icon-10'></li>
                    </ul>
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='room-rate'>
                    <h3>From</h3>
                    <h1>₦ 40,000</h1>
                    <Link to='/booking'>Book Now</Link>
                  </div>
                </div>
              </div>
              <hr />
            </div>

            <div className='col-md-12'>
              <div className='row'>
                <div className='col-md-3'>
                  <div className='room-img'>
                    <div className='box12'>
                      <img src='/img/vip-suite.jpg' alt='vip room' />
                      <div className='box-content'>
                        <h3 className='title'>vip room</h3>
                        <ul className='icon'>
                          <li>
                            <Link
                              to='/booking'
                              data-toggle='modal'
                              data-target='#modal-id4'
                            >
                              <i className='fa fa-link'></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='room-des'>
                    <h3>
                      <button
                        className='link'
                        data-toggle='modal'
                        data-target='#modal-id4'
                      >
                        Vip Room
                      </button>
                    </h3>
                    <p>
                      Our most luxurious offering awaits you with a King size
                      Bed, private bedroom, and a separate sitting room
                    </p>
                    <ul className='room-size'></ul>
                    <ul className='room-icon'>
                      <li className='icon-1'></li>
                      <li className='icon-2'></li>
                      <li className='icon-3'></li>
                      <li className='icon-4'></li>
                      <li className='icon-5'></li>
                      <li className='icon-6'></li>
                      <li className='icon-7'></li>
                      <li className='icon-8'></li>
                      <li className='icon-9'></li>
                      <li className='icon-10'></li>
                    </ul>
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='room-rate'>
                    <h3>From</h3>
                    <h1> ₦ 70,000 </h1>
                    <Link to='/booking'>Book now</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal for Room Section Start --> */}
      <div id='modal-id1' className='modal fade' tabIndex='-1' role='dialog'>
        <div className='modal-dialog modal-lg'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button type='button' className='close' data-dismiss='modal'>
                &times;
              </button>
            </div>
            <div className='modal-body'>
              <div className='row'>
                <div className='col-12'>
                  <div className='port-slider'>
                    <div>
                      <img src='/img/single-room.jpg' alt='single-room' />
                    </div>
                  </div>
                  <div className='port-slider-nav'></div>
                </div>
                <div className='col-12'>
                  <p className='my-5'>
                    <span className='room-details'>Single Room:</span>{' '}
                    Experience comfort with a Single Queen Bed, a 32-inch smart
                    TV with cable channels, and a cozy reading/laptop table.
                    Enjoy the convenience of climate-controlled air
                    conditioning, non-smoking environment, pool access, a
                    hairdryer, and two towels.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='modal-id3' className='modal fade' tabIndex='-1' role='dialog'>
        <div className='modal-dialog modal-lg'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button type='button' className='close' data-dismiss='modal'>
                &times;
              </button>
            </div>
            <div className='modal-body'>
              <div className='row'>
                <div className='col-12'>
                  <div className='port-slider'>
                    <div>
                      <img src='\img\executive.jpg' alt='exeecutive-room' />
                    </div>
                  </div>
                  <div className='port-slider-nav'></div>
                </div>
                <div className='col-12'>
                  <p className='my-5'>
                    <span className='room-details'>Executive Suite:</span>{' '}
                    Elevate your experience in a super spacious suite with a
                    King size Bed, a 55-inch smart HD TV offering cable
                    channels, and complimentary WiFi. Delight in the luxury of a
                    double-size sofa, balcony space, and a lavish bathroom.
                    Enjoy privileges like free airport pickup and drop off,
                    early check-in, and late check-out. Also, benefit from
                    climate-controlled air conditioning, pool access, a
                    hairdryer, and three towels.
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='modal-id5' className='modal fade' tabIndex='-1' role='dialog'>
        <div className='modal-dialog modal-lg'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button type='button' className='close' data-dismiss='modal'>
                &times;
              </button>
            </div>
            <div className='modal-body'>
              <div className='row'>
                <div className='col-12'>
                  <div className='port-slider'>
                    <div>
                      <img src='/img/delux.jpg' alt='delux' />
                    </div>
                  </div>
                  <div className='port-slider-nav'></div>
                </div>
                <div className='col-12'>
                  <p className='my-5'>
                    <span className='room-details'>Standard plus-Delux:</span>
                    Experience comfort with a Very spacious room, Double seat
                    Sofa, Single Queen Bed, a 42 inch smart Tv with Cable
                    channels. Balcony space.Work or unwind at the reading/laptop
                    table, and indulge in climate-controlled air conditioning,
                    non-smoking surroundings, pool access, a hairdryer, and two
                    towels.
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id='modal-id2' className='modal fade' tabIndex='-1' role='dialog'>
        <div className='modal-dialog modal-lg'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button type='button' className='close' data-dismiss='modal'>
                &times;
              </button>
            </div>
            <div className='modal-body'>
              <div className='row'>
                <div className='col-12'>
                  <div className='port-slider'>
                    <div>
                      <img src='/img/standard-room.jpg' alt='standard-room' />
                    </div>
                  </div>
                  <div className='port-slider-nav'></div>
                </div>
                <div className='col-12'>
                  <p className='my-5'>
                    <span className='room-details'>Standard Room:</span> Embrace
                    spaciousness featuring a Single Queen Bed, a 43-inch smart
                    TV with cable channels, and a relaxing single-seat sofa.
                    Work or unwind at the reading/laptop table, and indulge in
                    climate-controlled air conditioning, non-smoking
                    surroundings, pool access, a hairdryer, and two towels.
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='modal-id4' className='modal fade' tabIndex='-1' role='dialog'>
        <div className='modal-dialog modal-lg'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button type='button' className='close' data-dismiss='modal'>
                &times;
              </button>
            </div>
            <div className='modal-body'>
              <div className='row'>
                <div className='col-12'>
                  <div className='port-slider'>
                    <div>
                      <img src='/img/vip-suite.jpg' alt='vip room' />
                    </div>
                  </div>
                  <div className='port-slider-nav'></div>
                </div>
                <div className='col-12'>
                  <p className='my-5'>
                    <span className='room-details'>VIP Room:</span> Our most
                    luxurious offering awaits you with a King size Bed, private
                    bedroom, and a separate sitting room, complemented by a
                    balcony with stunning views. Indulge in a 60-inch smart HD
                    TV with cable channels, complimentary WiFi, and exclusive
                    amenities like breakfast, complimentary dinner, and room
                    service. Experience the comfort of climate-controlled air
                    conditioning, a luxury-style bathroom, and non-smoking
                    ambience. Take advantage of pool access, a hairdryer, and
                    four towels, while our 24/7 customer service support ensures
                    a seamless stay.
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeRooms
