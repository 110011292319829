import React from 'react'
import '../css/header-slide.css'
const HeaderSlide = () => {
  return (
    <div>
      <div id='headerSlider' className='carousel slide' data-ride='carousel'>
        <ol className='carousel-indicators'>
          <li
            data-target='#headerSlider'
            data-slide-to='0'
            className='active'
          ></li>
          <li data-target='#headerSlider' data-slide-to='1'></li>
          <li data-target='#headerSlider' data-slide-to='2'></li>
        </ol>
        <div className='carousel-inner'>
          <div className='carousel-item active'>
            <img src='/img/zayton-day.jpeg' alt='zaytun-day' />
            <div className='carousel-caption'>
              <h1 className='animated fadeInRight'>
                Book Your Holidays With Zaytun Hotels
              </h1>
            </div>
          </div>

          <div className='carousel-item'>
            <img src='/img/zaytun-nite.jpeg' alt='zaytun-night' />
            <div className='carousel-caption'>
              <h1 className='animated fadeInLeft'>Your Home Away From Home</h1>
            </div>
          </div>

          <div className='carousel-item'>
            <img src='/img/slide2.jpg' alt='Royal Hotel' />
            <div className='carousel-caption'>
              <h1 className='animated fadeInRight'>
                A dream stay at best price
              </h1>
            </div>
          </div>

          <div className='carousel-item'>
            <img src='/img/city1.jpg' alt='city1' />
            <div className='carousel-caption'>
              <h1 className='animated fadeInRight'>
                we offer free airport pick up and drop off
              </h1>
            </div>
          </div>
        </div>

        <a
          className='carousel-control-prev'
          href='#headerSlider'
          role='button'
          data-slide='prev'
        >
          <span
            className='carousel-control-prev-icon'
            aria-hidden='true'
          ></span>
          <span className='sr-only'>Previous</span>
        </a>
        <a
          className='carousel-control-next'
          href='#headerSlider'
          role='button'
          data-slide='next'
        >
          <span
            className='carousel-control-next-icon'
            aria-hidden='true'
          ></span>
          <span className='sr-only'>Next</span>
        </a>
      </div>
    </div>
  )
}

export default HeaderSlide
