import React, { useState } from 'react'
import '../css/contact.css'

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  })

  const [confirmation, setConfirmation] = useState(null)
    const [isLoading, setIsLoading] = useState(null)

  const handleSubmit = async (e) => {

    e.preventDefault()

    if (
      formData.name === '' ||
      !formData.email ||
      !formData.subject ||
      !formData.message
    ) {
      // If any required field is missing, display an error message
      setConfirmation('Please fill in all required fields.')
      return
    }
setIsLoading(
  <div className='overlay'>
    <div className='loader-dev'>
      <div className='loader'></div>
    </div>
  </div>
)
    try {
      const response = await fetch(
        'https://backendcms.zaytunhotels.com/api/contact-us',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      )

      if (response.ok) {
        setConfirmation(
          <div id='success'>
            <p>messge sent successful!</p>
          </div>
        )
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: '',
        })
        setIsLoading(null)
      } else {
        setConfirmation(
          <div id='success'>
            <p>message sending failed. Please try again later.</p>
          </div>
        )
      }
    } catch (error) {
      setConfirmation(
        <div id='success'>
          <p>
            An error occurred on the server. Please try again later or use
            other contact medium.
          </p>
        </div>
      )
    }
  }

  const handleInputChange = (e) => {
    const { id, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }))
  }

  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='section-header'>
          {isLoading}
            <h2>Contact</h2>
            <p>
              For more information about our services, suggestions and
              compliants, feel free to contact us
            </p>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='row contact-info'>
                <div className='col-md-3'>
                  <div className='info-item'>
                    <p>
                      <i className='fa fa-map-marker'></i>ZayTun Tower Along
                      Zungeru Road, Fagge LGA Kano, Kano State.Nigeria
                    </p>
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='info-item'>
                    <p>
                      <i className='fa fa-envelope'></i>
                      <a href='mailto:info@zaytunhotels.com'>
                        info@zaytunhotels.com
                      </a>
                    </p>
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='info-item'>
                    <p>
                      <i className='fa fa-phone'></i>
                      <a href='tel:+1 234 567 8900'>+2348031531912</a>
                    </p>
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='info-item'>
                    <p>
                      <i className='fas fa fa-whatsapp mr-3'></i>
                      <a href='https://wa.me/+2348031531912' className='cw'>
                        +2348031531912
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-12'>
              <div className='contact-form'>
               {confirmation}
                <form
                  name='sentMessage'
                  id='contactForm'
                  noValidate='novalidate'
                  onSubmit={handleSubmit}
                >
                  <div className='form-row'>
                    <div className='control-group col-sm-6'>
                      <label>Your Name</label>
                      <input
                      value={formData.name}
                        type='text'
                        className='form-control'
                        id='name'
                        placeholder='E.g. ZAY TUN'
                        required='required'
                        data-validation-required-message='Please enter your name'
                        onChange={handleInputChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                    <div className='control-group col-sm-6'>
                      <label>Email</label>
                      <input
                      value={formData.email}
                        type='email'
                        className='form-control'
                        id='email'
                        placeholder='E.g. email@example.com'
                        required='required'
                        data-validation-required-message='Please enter your email'
                        onChange={handleInputChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='control-group'>
                    <label>Subject</label>
                    <input
                    value={formData.subject}
                      type='text'
                      className='form-control'
                      id='subject'
                      placeholder='E.g. Room Booking'
                      required='required'
                      data-validation-required-message='Please enter a subject'
                      onChange={handleInputChange}
                    />
                    <p className='help-block text-danger'></p>
                  </div>
                  <div className='control-group'>
                    <label>Message</label>
                    <textarea
                      className='form-control'
                      value={formData.message}
                      id='message'
                      rows='5'
                      placeholder='E.g. I need a premium room'
                      required='required'
                      data-validation-required-message='Please enter your message'
                      onChange={handleInputChange}
                    ></textarea>
                    <p className='help-block text-danger'></p>
                  </div>
                  <div className='button'>
                    <button type='submit'>Send Message</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
