
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import '../css/header.css'

const Header = () => {
  useEffect(() => {
    const createMobileMenu = () => {
      // Find the element with id 'header'
      const header = document.getElementById('header')

      // Check if mobile menu already exists to prevent duplication
      if (!document.querySelector('.mobile-menu')) {
        // Create a new div element with the class 'mobile-menu'
        const mobileMenu = document.createElement('div')
        mobileMenu.className = 'mobile-menu d-xl-none'

        // Clone the elements with class 'top-menu' and append them to the 'mobile-menu'
        document.querySelectorAll('.top-menu').forEach((topMenu) => {
          mobileMenu.appendChild(topMenu.cloneNode(true))
        })

        // Insert the new 'mobile-menu' div after the 'header' element
        header.parentNode.insertBefore(mobileMenu, header.nextSibling)

        // Find the element with class 'mobile-menu-btn'
        const mobileMenuBtn = document.querySelector('.mobile-menu-btn')

        // Attach a click event listener to the 'mobile-menu-btn'
        const handleMobileMenuToggle = () => {
          // Toggle the visibility of the 'mobile-menu' using slide animation
          mobileMenu.style.display =
            mobileMenu.style.display === 'none' ||
            mobileMenu.style.display === ''
              ? 'block'
              : 'none'
        }

        mobileMenuBtn.addEventListener('click', handleMobileMenuToggle)

        // Cleanup event listener on component unmount
        return () => {
          mobileMenuBtn.removeEventListener('click', handleMobileMenuToggle)
        }
      }
    }

    createMobileMenu()
  }, [])

  return (
    <>
      <header id='header'>
        <Link to='/' className='logo'>
          <img src='/img/logo.png' alt='Logo' />
        </Link>
        <div className='phone'>
          <i className='fa fa-phone'></i>
          <Link to='tel:+2348031531912' className='num'>
            +2348031531912
          </Link>
        </div>
        <div className='mobile-menu-btn'>
          <i className='fa fa-bars'></i>
        </div>
        <nav className='main-menu top-menu'>
          <ul>
            <li className='active'>
              <Link to='/'>Home</Link>
            </li>
            <li>
              <Link to='/about'>About Us</Link>
            </li>

            <li>
              <Link to='/room'>Rooms</Link>
            </li>

            <li>
              <Link to='/amenities'>Amenities</Link>
            </li>

            <li>
              <Link to='/booking'>Booking</Link>
            </li>

            <li>
              <Link to='/contact'>Contact Us</Link>
            </li>
          </ul>
        </nav>
      </header>
    </>
  )
}

export default Header
