import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import '../css/room-booking.css'
import '../css/datepicker.css'

const BookingForm = () => {
  const navigate = useNavigate() 
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    mobile: '',
    email: '',
    checkIn: null,
    checkOut: null,
    roomType: '',
    uniqueBookingCode: '',
  })
  const [confirmation, setConfirmation] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  
  const handleSubmit = async (e) => {
    e.preventDefault()
    // Generate a unique code for the booking
    function code() {
      const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      let result = ''
      for (let i = 0; i < 6; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        )
      }
      return result
    }

    const uniqueCode = code()
    const updatedFormData = {
      ...formData,
      uniqueBookingCode: uniqueCode,
    }

    if (
      updatedFormData.fname === '' ||
      !updatedFormData.lname ||
      !updatedFormData.checkIn ||
      !updatedFormData.checkOut ||
      !updatedFormData.roomType
    ) {
      setConfirmation(
        <div id='success'>
          <p>Please fill in all required fields.</p>
        </div>
      )
      return
    }
    setIsLoading(
      <div className='overlay'>
        <div className='loader-dev'>
          <div className='loader'></div>
        </div>
      </div>
    )
    try {
      const response = await fetch(
        'https://backend.zaytunhotels.com/api/bookings',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedFormData),
        }
      )
      if (response.ok) {
        setFormData({
          fname: '',
          lname: '',
          mobile: '',
          email: '',
          checkIn: null,
          checkOut: null,
          request: '',
          roomType: '',
          uniqueBookingCode: '',
        })

        const { fname, lname, uniqueBookingCode } = updatedFormData
        const bookingDetails = {
          firstname: fname,
          lastname: lname,
          bookingCode: uniqueBookingCode,
        }
        navigate('/confirmation', { state: { bookingDetails } })
      } else {
        setConfirmation(
          <div id='success'>
            <p>Booking failed. Please try again.</p>
          </div>
        )
      }
    } catch (error) {
      setConfirmation(
        <div id='success'>
          <p>Network error. Please try again.</p>
        </div>
      )
    }
  }

  const handleDateChange = (date, id) => {
    setFormData((prevData) => ({
      ...prevData,
      [id]: date,
    }))
  }

  const handleInputChange = (e) => {
    const { id, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }))
  }

  return (
    <div id='booking' className='reveal'>
      <div className='container'>
        <div className='section-header'>
          <h2>Room Booking</h2>
          <p>
            Secure your unforgettable stay at ZayTun Hotel now. Whether you're
            planning a leisurely getaway, a business trip, or a special
            occasion, our seamless online booking process ensures that your
            experience starts off on the right foot.
          </p>
        </div>
        <div className='row2'>
          <div className='col-12'></div>
          <div className='booking-form'>
            {{ confirmation } && <p>{confirmation}</p>}
            {isLoading}
            <form
              name='sentMessage'
              id='bookingForm'
              noValidate='novalidate'
              onSubmit={handleSubmit}
            >
              <div className='form-row'>
                <div className='control-group col-sm-6'>
                  <label htmlFor='fname'>First Name</label>
                  <input
                    onChange={handleInputChange}
                    type='text'
                    className='form-control'
                    id='fname'
                    value={formData.fname}
                    placeholder='E.g. zay'
                    required='required'
                    data-validation-required-message='Please enter first name'
                  />
                  <p className='help-block text-danger'></p>
                </div>
                <div className='control-group col-sm-6'>
                  <label htmlFor='lname'>Last Name</label>
                  <input
                    onChange={handleInputChange}
                    type='text'
                    className='form-control'
                    id='lname'
                    value={formData.lname}
                    placeholder='E.g. Tun'
                    required='required'
                    data-validation-required-message='Please enter last name'
                  />
                  <p className='help-block text-danger'></p>
                </div>
              </div>
              <div className='form-row'>
                <div className='control-group col-sm-6'>
                  <label htmlFor='mobile'>Mobile</label>
                  <input
                    onChange={handleInputChange}
                    type='text'
                    className='form-control'
                    id='mobile'
                    value={formData.mobile}
                    placeholder='E.g. 08000000000'
                    required='required'
                    data-validation-required-message='Please enter your mobile number'
                  />
                  <p className='help-block text-danger'></p>
                </div>
                <div className='control-group col-sm-6'>
                  <label htmlFor='email'>Email</label>
                  <input
                    onChange={handleInputChange}
                    type='email'
                    className='form-control'
                    id='email'
                    value={formData.email}
                    placeholder='E.g. email@example.com'
                    required='required'
                    data-validation-required-message='Please enter your email'
                  />
                  <p className='help-block text-danger'></p>
                </div>
              </div>
              <div className='form-row'>
                <div className='control-group col-sm-6'>
                  <label className='blo' id='one'>
                    Check-In
                  </label>
                  <DatePicker
                    id='one'
                    selected={formData.checkIn}
                    onChange={(date) => handleDateChange(date, 'checkIn')}
                    className='form-control'
                    placeholderText='MM/DD/YYYY'
                    required
                  />
                  <p className='help-block text-danger'></p>
                </div>
                <div className='control-group col-sm-6'>
                  <label className='blo' id='two'>
                    Check-Out
                  </label>
                  <DatePicker
                    id='two'
                    selected={formData.checkOut}
                    onChange={(date) => handleDateChange(date, 'checkOut')}
                    className='form-control'
                    placeholderText='MM/DD/YYYY'
                    required
                  />
                  <p className='help-block text-danger'></p>
                </div>
              </div>
              <div className='form-row'>
                <div className='control-group col-sm-6'>
                  <label htmlFor='roomType'>Select Room Type</label>
                  <select
                    className='form-control'
                    id='roomType'
                    onChange={handleInputChange}
                    value={formData.roomType}
                  >
                    <option defaultValue='selected'>choose Room type</option>
                    <option>Single Room</option>
                    <option>Standard Room</option>
                    <option>Executive Room</option>
                    <option>Delux room</option>
                    <option>VIP Room</option>
                  </select>
                </div>
                <div className='control-group'>
                  <label htmlFor='request'>Special Request</label>
                  <input
                    onChange={handleInputChange}
                    type='text'
                    className='form-control'
                    id='request'
                    value={formData.request}
                    placeholder='E.g. Special Request'
                    required='required'
                    data-validation-required-message='Please enter your special request'
                  />
                  <p className='help-block text-danger'></p>
                </div>
                <div className='button'>
                  <button type='submit' id='bookingButton'>
                    Book Now
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BookingForm
