import React from 'react'
import '../css/about.css'

const About = () => {
  return (
    <div>
      <div id='about'>
        <div className='container'>
          <div className='section-header'>
            <h2>About ZAYTUN Hotel</h2>
            <p>
              Welcome to ZayTun Hotel, your perfect haven at the heart of the
              city. Situated just 2km from the Aminu Kano local and
              international airport, we proudly hold the distinction of being
              the nearest hotel to this bustling gateway, providing unmatched
              convenience for travelers.
            </p>
          </div>
          <div className='row'>
            <div className='col-md-6 img-cols'>
              <div className='img-col'>
                <img
                  className='img-fluid'
                  src='/img/zayton-day.jpeg'
                  alt='zaytun-day'
                />
              </div>
            </div>
            <div className='col-md-6 content-cols'>
              <div className='content-col'>
                <h3>Strategic location</h3>
                <p>
                  Nestled strategically along the main tarred road, our location
                  ensures easy access to public transportation, major trading
                  markets, and a plethora of shopping opportunities. Moreover,
                  you'll find us within a stone's throw from key banking
                  facilities, allowing for seamless financial transactions
                  during your stay.
                </p>
              </div>
            </div>
          </div>

          <hr />

          <div className='row'>
            <div className='col-md-6 img-cols d-block d-md-none'>
              <div className='img-col'>
                <img className='img-fluid' src='/img/city2.jpg' alt='city' />
              </div>
            </div>
            <div className='col-md-6 content-cols'>
              <div className='content-col'>
                <h3>convenience</h3>
                <p>
                  At ZayTun Hotel, we redefine your travel experience. Our
                  commitment to excellence begins with your arrival, as we
                  extend a warm welcome through our FREE airport pickup and
                  drop-off service. Modernity meets comfort within our walls,
                  where cutting-edge technology harmonizes with contemporary
                  amenities. Enjoy the convenience of complimentary printing,
                  scanning, and photocopying services, along with the added perk
                  of foreign currency exchange to simplify your financial needs.
                </p>
              </div>
            </div>
            <div className='col-md-6 img-cols d-none d-md-block'>
              <div className='img-col'>
                <img className='img-fluid' src='/img/city2.jpg' alt='city2'/>
              </div>
            </div>
          </div>

          <hr />

          <div className='row'>
            <div className='col-md-6 img-cols'>
              <div className='img-col'>
                <img
                  className='img-fluid'
                  src='img/vip-draw.jpeg' alt='vip-draw'
                />
              </div>
            </div>
            <div className='col-md-6 content-cols'>
              <div className='content-col'>
                <h3>seamless</h3>
                <p>
                  Embracing diversity, ZayTun Hotel proudly accepts all payment
                  methods, catering to both local and foreign guests, and
                  accommodating various credit cards. Our staff is not just
                  courteous but genuinely friendly, embodying our commitment to
                  personalized service that ensures your comfort throughout your
                  stay.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
